"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.detachEvents = exports.attachEvents = exports.playerHasTrackEvents = exports.addEventText = void 0;
var main_1 = require("./main");
var VideoJSPlayer_1 = require("./players/VideoJSPlayer");
var stats_1 = require("./stats");
var dateWithMs = function () {
    var addZero = function (x, n) {
        var ret = x.toString();
        while (ret.length < n) {
            ret = '0' + ret;
        }
        return ret;
    };
    var d = new Date();
    var h = addZero(d.getHours(), 2);
    var m = addZero(d.getMinutes(), 2);
    var s = addZero(d.getSeconds(), 2);
    var ms = addZero(d.getMilliseconds(), 3);
    return h + ':' + m + ':' + s + ':' + ms;
};
var EVENTS = [
    'abort',
    'canplay',
    'canplaythrough',
    'durationchange',
    'emptied',
    'ended',
    'error',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    // 'progress',
    'ratechange',
    'resize',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    // 'timeupdate',
    'transitionend',
    'volumechange',
    'waiting',
];
var eventColors = ['lightblue', 'lightgreen', 'lightcyan', 'lightgoldenrodyellow'];
var eventsContainer = document.getElementById('events');
var MAX_NB_EVENTS = 30;
var colorIndex = 0;
var currentAudioTracks;
var currentTextTracks;
var addEventText = function (text, color) {
    if ((eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children) && (eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children.length) > MAX_NB_EVENTS) {
        for (var i = 0; i < (eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children.length) - MAX_NB_EVENTS; i++) {
            eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.children[i].remove();
        }
    }
    var eventSpan = document.createElement('span');
    eventSpan.style.backgroundColor = color !== null && color !== void 0 ? color : eventColors[colorIndex];
    colorIndex = (colorIndex + 1) % eventColors.length;
    eventSpan.innerHTML = text;
    eventsContainer === null || eventsContainer === void 0 ? void 0 : eventsContainer.appendChild(eventSpan);
    (color ? console.warn : console.log)("".concat(dateWithMs(), " ").concat(text));
};
exports.addEventText = addEventText;
var playerHasTrackEvents = function (player) { return player instanceof VideoJSPlayer_1.VideoJSPlayer; };
exports.playerHasTrackEvents = playerHasTrackEvents;
var showAudioTracks = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var audioTracks = player.audioTracks();
    (0, exports.addEventText)("audios change: ".concat(audioTracks === null || audioTracks === void 0 ? void 0 : audioTracks.length, " "));
};
var showAudioTracksAdd = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var audioTracks = player.audioTracks();
    (0, exports.addEventText)("audios add: ".concat(audioTracks === null || audioTracks === void 0 ? void 0 : audioTracks.length, " "));
};
var showTextTracks = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var textTracks = player.textTracks();
    (0, exports.addEventText)("texts change: ".concat(textTracks === null || textTracks === void 0 ? void 0 : textTracks.length, " "));
};
var showTextTracksAdd = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var textTracks = player.textTracks();
    (0, exports.addEventText)("texts add: ".concat(textTracks === null || textTracks === void 0 ? void 0 : textTracks.length, " "));
};
var showEvent = function (event) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var player = (0, main_1.getCurrentPlayer)();
    if ((0, exports.playerHasTrackEvents)(player)) {
        var audioTracks = (_a = player === null || player === void 0 ? void 0 : player.audioTracks) === null || _a === void 0 ? void 0 : _a.call(player);
        var textTracks = (_b = player === null || player === void 0 ? void 0 : player.textTracks) === null || _b === void 0 ? void 0 : _b.call(player);
        if (audioTracks !== currentAudioTracks) {
            currentAudioTracks = audioTracks;
            var listening = '';
            (_c = currentAudioTracks === null || currentAudioTracks === void 0 ? void 0 : currentAudioTracks.off) === null || _c === void 0 ? void 0 : _c.call(currentAudioTracks, 'change', showAudioTracks);
            (_d = audioTracks === null || audioTracks === void 0 ? void 0 : audioTracks.on) === null || _d === void 0 ? void 0 : _d.call(audioTracks, 'change', showAudioTracks);
            (_e = currentAudioTracks === null || currentAudioTracks === void 0 ? void 0 : currentAudioTracks.removeEventListener) === null || _e === void 0 ? void 0 : _e.call(currentAudioTracks, 'addtrack', showAudioTracksAdd);
            (_f = audioTracks === null || audioTracks === void 0 ? void 0 : audioTracks.addEventListener) === null || _f === void 0 ? void 0 : _f.call(audioTracks, 'addtrack', showAudioTracksAdd);
            listening = (audioTracks === null || audioTracks === void 0 ? void 0 : audioTracks.on) ? 'listening ' : '';
            (0, exports.addEventText)("new audios: ".concat(audioTracks === null || audioTracks === void 0 ? void 0 : audioTracks.length, " ").concat(listening));
        }
        if (textTracks !== currentTextTracks) {
            currentTextTracks = textTracks;
            (_g = currentTextTracks === null || currentTextTracks === void 0 ? void 0 : currentTextTracks.off) === null || _g === void 0 ? void 0 : _g.call(currentTextTracks, 'change', showTextTracks);
            (_h = textTracks === null || textTracks === void 0 ? void 0 : textTracks.on) === null || _h === void 0 ? void 0 : _h.call(textTracks, 'change', showTextTracks);
            (_j = currentTextTracks === null || currentTextTracks === void 0 ? void 0 : currentTextTracks.removeEventListener) === null || _j === void 0 ? void 0 : _j.call(currentTextTracks, 'addtrack', showTextTracksAdd);
            (_k = textTracks === null || textTracks === void 0 ? void 0 : textTracks.addEventListener) === null || _k === void 0 ? void 0 : _k.call(textTracks, 'addtrack', showTextTracksAdd);
            // @ts-ignore
            var listening = (textTracks === null || textTracks === void 0 ? void 0 : textTracks.on) ? 'listening ' : '';
            (0, exports.addEventText)("new texts: ".concat(textTracks === null || textTracks === void 0 ? void 0 : textTracks.length, "  ").concat(listening));
        }
    }
    if (event.type === 'canplay' || event.type === 'canplaythrough') {
        (0, stats_1.loadEnd)();
    }
    if (event.type === 'error') {
        var error = document.getElementById('error');
        if (error) {
            var message = 'unknown';
            if ((_l = player === null || player === void 0 ? void 0 : player.player) === null || _l === void 0 ? void 0 : _l.error()) {
                message = "".concat((_o = (_m = player === null || player === void 0 ? void 0 : player.player) === null || _m === void 0 ? void 0 : _m.error()) === null || _o === void 0 ? void 0 : _o.code, " - ").concat((_q = (_p = player === null || player === void 0 ? void 0 : player.player) === null || _p === void 0 ? void 0 : _p.error()) === null || _q === void 0 ? void 0 : _q.message);
            }
            error.innerHTML = "Error: ".concat(message);
        }
    }
    (0, stats_1.showResolution)();
    (0, exports.addEventText)("".concat(event.type, " "));
};
var attachEvents = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    EVENTS.forEach(function (event) {
        player.attachEvent(event, showEvent);
    });
};
exports.attachEvents = attachEvents;
var detachEvents = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    EVENTS.forEach(function (event) {
        player.detachEvent(event, showEvent);
    });
};
exports.detachEvents = detachEvents;
