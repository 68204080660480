"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createFocusableButton = void 0;
var REGEX = /row-(\d+)-column-(\d+)/;
var createFocusableButton = function (_a) {
    var row = _a.row, column = _a.column, label = _a.label, callback = _a.callback;
    var button = document.createElement('button');
    button.className = "row-".concat(row, "-column-").concat(column);
    button.innerHTML = label;
    if (callback) {
        button.addEventListener('click', callback);
    }
    return button;
};
exports.createFocusableButton = createFocusableButton;
document.body.addEventListener('keydown', function (event) {
    var _a, _b, _c, _d, _e, _f;
    var match = (_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.className.match(REGEX);
    if (!match) {
        (_b = document.getElementsByClassName('row-0-column-0')[0]) === null || _b === void 0 ? void 0 : _b.focus();
        return;
    }
    var activeRow = match[1];
    var activeColumn = match[2];
    if (event.key === 'ArrowLeft') {
        var previousColumn = parseInt(activeColumn) - 1;
        (_c = document.getElementsByClassName("row-".concat(activeRow, "-column-").concat(previousColumn))[0]) === null || _c === void 0 ? void 0 : _c.focus();
    }
    else if (event.key === 'ArrowRight') {
        var nextColumn = parseInt(activeColumn) + 1;
        (_d = document.getElementsByClassName("row-".concat(activeRow, "-column-").concat(nextColumn))[0]) === null || _d === void 0 ? void 0 : _d.focus();
    }
    else if (event.key === 'ArrowUp') {
        var previousRow = parseInt(activeRow) - 1;
        (_e = document.getElementsByClassName("row-".concat(previousRow, "-column-0"))[0]) === null || _e === void 0 ? void 0 : _e.focus();
    }
    else if (event.key === 'ArrowDown') {
        var nextRow = parseInt(activeRow) + 1;
        (_f = document.getElementsByClassName("row-".concat(nextRow, "-column-0"))[0]) === null || _f === void 0 ? void 0 : _f.focus();
    }
});
