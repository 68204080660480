"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showResolution = exports.clearResolution = exports.loadEnd = exports.loadStart = void 0;
var main_1 = require("./main");
var loadStartTs = -1;
var loadEndTs = -1;
var loadStart = function () {
    loadEndTs = -1;
    loadStartTs = performance.now();
    var stats = document.getElementById('stats');
    if (!stats)
        return;
    stats.innerHTML = '';
};
exports.loadStart = loadStart;
var loadEnd = function () {
    if (loadEndTs === -1) {
        loadEndTs = performance.now();
        var stats = document.getElementById('stats');
        if (!stats)
            return;
        stats.innerHTML = "Load duration: ".concat(Math.round(loadEndTs - loadStartTs), "ms");
    }
};
exports.loadEnd = loadEnd;
var clearResolution = function () {
    var resolutionContainer = document.getElementById('resolution');
    if (!resolutionContainer)
        return;
    resolutionContainer.innerHTML = '';
};
exports.clearResolution = clearResolution;
var showResolution = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var resolutionContainer = document.getElementById('resolution');
    if (!resolutionContainer || !(0, main_1.isLetterboxPlayer)(player))
        return;
    var resolution = player.getResolution();
    if (!resolution)
        return;
    resolutionContainer.innerHTML = "Resolution: ".concat(resolution.width, "x").concat(resolution.height);
};
exports.showResolution = showResolution;
