"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.attachTracks = void 0;
var controls_1 = require("./controls");
var main_1 = require("./main");
var tracksContainer = document.getElementById('tracks');
var listTracks = function (trackList, type) {
    var _a, _b, _c;
    var count = trackList === null || trackList === void 0 ? void 0 : trackList.length;
    var langs = [];
    if (count !== undefined) {
        for (var i = 0; i < count; i++) {
            var track = trackList === null || trackList === void 0 ? void 0 : trackList[i];
            var l = track === null || track === void 0 ? void 0 : track.language;
            // @ts-ignore
            if (track === null || track === void 0 ? void 0 : track.enabled)
                l = l === null || l === void 0 ? void 0 : l.toUpperCase();
            langs.push(l !== null && l !== void 0 ? l : '?');
        }
    }
    var expectCount = (_c = (_b = (_a = (0, controls_1.getCurrentStream)()) === null || _a === void 0 ? void 0 : _a.expect) === null || _b === void 0 ? void 0 : _b[type]) === null || _c === void 0 ? void 0 : _c.count;
    var result = "".concat(type, ": ").concat(count, ":").concat(langs.join(', '));
    if (expectCount) {
        var color = expectCount !== count ? 'red' : 'green';
        return "<span style=\"color: ".concat(color, "\">").concat(result, "</span>");
    }
    return result;
};
var interval = null;
var attachTracks = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    if (interval)
        clearInterval(interval);
    interval = setInterval(function () {
        if (tracksContainer) {
            tracksContainer.innerHTML = "".concat(listTracks(player.audioTracks(), 'audioTracks'), " ").concat(listTracks(player.textTracks(), 'textTracks'));
        }
    }, 500);
};
exports.attachTracks = attachTracks;
