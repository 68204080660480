"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLoaders = exports.getCurrentStream = exports.destroyControls = exports.createControls = void 0;
var events_1 = require("./events");
var focus_1 = require("./focus");
var main_1 = require("./main");
var stats_1 = require("./stats");
var createControls = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    var controls = [
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 0,
            label: 'Play',
            callback: function () {
                (0, events_1.addEventText)('play', 'yellow');
                player.play();
            },
        }),
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 1,
            label: 'Pause',
            callback: function () {
                (0, events_1.addEventText)('pause', 'yellow');
                player.pause();
            },
        }),
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 2,
            label: 'Seek to 0',
            callback: function () {
                (0, events_1.addEventText)('seek to 0', 'yellow');
                player.seekTo0();
            },
        }),
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 3,
            label: 'Audio 0',
            callback: function () {
                var _a;
                (0, events_1.addEventText)('audio 0', 'yellow');
                var audioTrack = (_a = player.audioTracks()) === null || _a === void 0 ? void 0 : _a[0];
                if (audioTrack) {
                    // @ts-ignore
                    audioTrack.enabled = true;
                }
            },
        }),
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 4,
            label: 'Audio 1',
            callback: function () {
                var _a;
                (0, events_1.addEventText)('audio 1', 'yellow');
                var audioTrack = (_a = player.audioTracks()) === null || _a === void 0 ? void 0 : _a[1];
                if (audioTrack) {
                    // @ts-ignore
                    audioTrack.enabled = true;
                }
            },
        }),
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 5,
            label: 'Text 0',
            callback: function () {
                var _a;
                (0, events_1.addEventText)('text 0', 'yellow');
                var textTrack = (_a = player.textTracks()) === null || _a === void 0 ? void 0 : _a[0];
                if (textTrack) {
                    // @ts-ignore
                    textTrack.enabled = true;
                }
            },
        }),
        (0, focus_1.createFocusableButton)({
            row: 1,
            column: 6,
            label: 'Text 1',
            callback: function () {
                var _a;
                (0, events_1.addEventText)('text 1', 'yellow');
                var textTrack = (_a = player.textTracks()) === null || _a === void 0 ? void 0 : _a[1];
                if (textTrack) {
                    // @ts-ignore
                    textTrack.enabled = true;
                }
            },
        }),
    ];
    var controlsContainer = document.getElementById('controls');
    controls.forEach(function (c) { return controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(c); });
};
exports.createControls = createControls;
var destroyControls = function () {
    var controlsContainer = document.getElementById('controls');
    if (!controlsContainer)
        return;
    controlsContainer.innerHTML = '';
};
exports.destroyControls = destroyControls;
var currentStream = null;
var getCurrentStream = function () { return currentStream; };
exports.getCurrentStream = getCurrentStream;
var createLoaders = function () {
    var player = (0, main_1.getCurrentPlayer)();
    if (!player)
        return;
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    var streams = require('./streams.json');
    var loaders = streams.map(function (s, i) {
        return (0, focus_1.createFocusableButton)({
            row: 2,
            column: i,
            label: s.name,
            callback: function () {
                (0, events_1.addEventText)("load ".concat(s.name), 'red');
                currentStream = s;
                (0, stats_1.loadStart)();
                player.load(s.url, s.type);
            },
        });
    });
    var controlsContainer = document.getElementById('controls');
    controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(document.createElement('br'));
    loaders.forEach(function (c) { return controlsContainer === null || controlsContainer === void 0 ? void 0 : controlsContainer.appendChild(c); });
};
exports.createLoaders = createLoaders;
